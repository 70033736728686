import { Text } from '@workos-inc/component-library';
import { FC } from 'react';
import { CopyInput } from '../../../../components/inputs';
import { Link } from '../../../../components/link';
import { usePortalSession } from '../../../../components/portal-session-provider';
import { StepData } from '../../../../shared/step-navigation/interfaces/step-data';
import { StepContent } from '../../../../shared/step-navigation/step-content';
import { useSsoStore } from '../../sso-store-provider';

const Component: FC = () => {
  const { appName } = usePortalSession();
  const { connection } = useSsoStore();

  return (
    <StepContent confirmLabel="I've created and configured Unsolicited SAML.">
      <Text>
        NOTE: These instructions are for connecting to Shibboleth using the{' '}
        <Link
          newTab
          appearance="highlight"
          href="https://shibboleth.atlassian.net/wiki/spaces/IDP4/pages/1265631696/UnsolicitedSSOConfiguration"
        >
          UnsolicitedSSOConfiguration
        </Link>
        . If your Enterprise customer requires the{' '}
        <Link
          newTab
          appearance="highlight"
          href="https://shibboleth.atlassian.net/wiki/spaces/IDP4/pages/1265631694/SAML2SSOConfiguration"
        >
          generic SAML 2.0 configuration
        </Link>{' '}
        instead, please use the Shibboleth Generic SAML Provider.
      </Text>

      <Text>
        Set up Unsolicited SAML in your Shibboleth instance. You'll need the
        following Assertion Consumer Service (ACS) URL. The ACS URL is the
        location your Shibboleth instance will redirect its authentication
        response to.
      </Text>

      <CopyInput
        id="saml-acs-url"
        label="Copy this ACS URL"
        value={connection.saml_acs_url}
      />

      <Text>
        The Service Provider Entity ID is a unique value which represents{' '}
        {appName} within your Shibboleth instance. Provide the value below to
        your Shibboleth Unsolicited SAML settings.
      </Text>

      <CopyInput
        id="saml-entity-id"
        label="Copy this SP Entity ID"
        value={connection.saml_entity_id}
      />

      <Text>
        For some setups, you can use the metadata found at the following SP
        Metadata link to configure the SAML connection.
      </Text>

      <CopyInput
        id="saml-sp-metadata-url"
        label="Copy this URL/XML"
        value={connection.saml_sp_metadata_url}
      />
    </StepContent>
  );
};

export const setupUnsolicitedSaml: StepData = {
  title: 'Setup Unsolicited SAML',
  component: Component,
};
