import { Text } from '@workos-inc/component-library';
import { FC } from 'react';
import { Img } from '../../../../components/image';
import { ExternalLink } from '../../../../components/link';
import { StepData } from '../../../../shared/step-navigation/interfaces/step-data';
import { StepContent } from '../../../../shared/step-navigation/step-content';

const Component: FC = () => (
  <StepContent confirmLabel="I’ve created a new application in ADP.">
    <Text>
      Login to the{' '}
      <ExternalLink
        newTab
        appearance="highlight"
        aria-label="Link to the ADP Apps Portal"
        href="https://adpapps.adp.com/self-service/projects"
      >
        ADP Partner Self Service Portal
      </ExternalLink>
    </Text>

    <Text>
      From this page there are two environments to select from: Development and
      Production. Please use the environment that best suits your use-case for
      this SSO connection.
    </Text>

    <Img
      priority
      height={710}
      src="/admin-portal/sso/adp-oidc/v1/adp-oidc-1.png"
      width={1000}
    />

    <Text>In the selected environment select "Create New Project".</Text>

    <Img
      priority
      height={710}
      src="/admin-portal/sso/adp-oidc/v1/adp-oidc-2.png"
      width={1000}
    />

    <Text>
      Give the project a meaningful name to designate the SSO connection. There
      is no need to add a description.
    </Text>

    <Img
      priority
      height={710}
      src="/admin-portal/sso/adp-oidc/v1/adp-oidc-3.png"
      width={1000}
    />

    <Text>
      Make the selections "US Marketplace" and "ADP Workforce Now" respectively
      for the next selections and then click "Next".
    </Text>

    <Img
      priority
      height={810}
      src="/admin-portal/sso/adp-oidc/v1/adp-oidc-4.png"
      width={900}
    />

    <Text>
      Finally, select "Other" for the use case that best describes your
      application and click "Submit".
    </Text>
  </StepContent>
);

export const createAProjectInTheAdpAppsPortal: StepData = {
  title: 'Create a Project in the ADP Apps Portal',
  component: Component,
};
