import { Text } from '@workos-inc/component-library';
import { FC } from 'react';
import { Link } from '../../../../components/link';
import { StepData } from '../../../../shared/step-navigation/interfaces/step-data';
import { StepContent } from '../../../../shared/step-navigation/step-content';

const Component: FC = () => (
  <StepContent confirmLabel="I’ve created an SSO application in Duo.">
    <Text>
      The Duo SAML integration is unique in that it requires a 3rd party IDP to
      federate the authentication. This means that along with the connection
      credentials, you’ll also need to configure a Single Sign on Authentication
      Source and a Cloud Application in your Duo Workspace.
    </Text>

    <Text>
      You may use any Duo-supported IDP to handle the Federated authentication.
      Since each IDP will have different ways of setting up the SSO connection
      between Duo and the IDP, please refer to the documentation that Duo
      provides to configure a Duo SSO Connection.
    </Text>

    <Text>
      <Link
        newTab
        appearance="highlight"
        aria-label="Link to the Duo SSO App documentation"
        href="https://duo.com/docs/sso#enable-duo-single-sign-on"
      >
        Duo Single Sign-on Application Documentation
      </Link>
    </Text>
  </StepContent>
);

export const createAnSsoConfigurationInDuo: StepData = {
  title: 'Create an SSO Configuration in Duo',
  component: Component,
};
