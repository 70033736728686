import { useFeatureToggle } from '@flopflip/react-broadcast';
import { FC } from 'react';
import * as React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { getIntentFromTokenOrCookie } from '../../utils/token';
import { Expired, NotFound } from '../error-pages';
import { UnsupportedProvider } from '../error-pages/unsupported-provider';
import { IntentRouter } from '../intent-router';
import { PortalSessionProvider } from '../portal-session-provider';
import { SetupLinkHandler } from '../setup-link-handler';

interface NewAdminPortalWrapperProps {
  children?: React.ReactNode;
}

const isServer = typeof window === 'undefined';

export const NewAdminPortalWrapper: FC<
  Readonly<NewAdminPortalWrapperProps>
> = ({ children }) => {
  // Temporary hotfix to avoid running Cypress against this new code path. DRGN-76 will migrate Cypress
  // to stop running against Development
  const isNewAdminPortalEnabled =
    process.env.NEXT_PUBLIC_IS_NEW_ADMIN_PORTAL_ENABLED === 'true';

  const token = window.location.search.split('token=')[1];
  const intent = getIntentFromTokenOrCookie(token);
  const isAdminPortalRevampEnabled = useFeatureToggle('adminPortalRevamp');
  const isDsyncAdminPortalRevampEnabled = useFeatureToggle(
    'dsyncAdminPortalRevamp',
  );

  if (
    (isAdminPortalRevampEnabled && isNewAdminPortalEnabled) ||
    (isDsyncAdminPortalRevampEnabled && intent === 'dsync') ||
    intent === 'audit_logs'
  ) {
    return (
      <div suppressHydrationWarning>
        {isServer ? null : (
          <Router>
            <Routes>
              <Route element={<SetupLinkHandler />} path="/s/:setupToken" />
              <Route element={<Expired />} path="/expired" />
              <Route element={<NotFound />} path="/not-found" />

              <Route
                element={<UnsupportedProvider />}
                path="/unsupported-provider"
              />

              <Route
                element={
                  <PortalSessionProvider>
                    <IntentRouter />
                  </PortalSessionProvider>
                }
                path="*"
              />
            </Routes>
          </Router>
        )}
      </div>
    );
  }

  return <>{children}</>;
};
