import { Text } from '@workos-inc/component-library';
import { FC } from 'react';
import { Img } from '../../../../components/image';
import { ExternalLink } from '../../../../components/link';
import { StepData } from '../../../../shared/step-navigation/interfaces/step-data';
import { MapAttributesStep } from '../../map-attributes-step/map-attributes-step';

const Component: FC = () => (
  <MapAttributesStep>
    <Text>
      In addition to configuring attribute mappings in CyberArk, you also need
      to map these additional attributes the application is expecting.
    </Text>

    <Text>
      To see the names of attributes that you can map from your Directory
      Provider, return to the "Provisioning" tab in your CyberArk SCIM
      application and expand the "Provisioning Script" section. Here CyberArk
      maps the attributes from their system to the standard attributes from the{' '}
      <ExternalLink
        newTab
        appearance="highlight"
        href={'https://datatracker.ietf.org/doc/html/rfc7643#section-4.1'}
      >
        SCIM RFC.{' '}
      </ExternalLink>
    </Text>

    <Img
      priority
      height={831}
      src="/admin-portal/directory-sync/cyberark-scim/v1/cyberark-scim-custom-attributes.png"
      width={1523}
    />

    <Text>
      If you would like to add any additional attributes here, please follow{' '}
      <ExternalLink
        newTab
        appearance="highlight"
        href={
          'https://docs.cyberark.com/Product-Doc/OnlineHelp/Idaptive/Latest/en/Content/Applications/AppsOvw/SCIM-Provisioning.htm'
        }
      >
        CyberArk's documentation{' '}
      </ExternalLink>
      on this process.
    </Text>
  </MapAttributesStep>
);

export const setUpAttributeMapping: StepData = {
  title: 'Set up Attribute Mapping',
  customAttributesStep: true,
  component: Component,
};
