import { SwitchIcon, Text } from '@workos-inc/component-library';
import { motion } from 'framer-motion';
import { ConnectionType, DirectoryType } from '../../../../graphql/generated';
import { ProviderLogo } from '../../../components/provider-logo';
import { AnyObject } from '../../../hooks/use-record-state';
import { StepDataWithPath } from '../interfaces/step-data';
import { SidebarNavItem } from './sidebar-nav-item';

interface SidebarProps<TFormData> {
  onClickSwitchProvider: () => void;
  provider: ConnectionType | DirectoryType;
  stepsData: StepDataWithPath<TFormData>[];
  switchProviderText: string;
  stepsWithErrors: boolean[];
}

const listVariants = {
  hidden: { opacity: 0 },
  show: {
    opacity: 1,
    transition: {
      staggerChildren: 0.05,
    },
  },
};

export const Sidebar = <TFormData extends AnyObject>({
  onClickSwitchProvider: handleClickSwitchProvider,
  provider,
  stepsData,
  switchProviderText,
  stepsWithErrors,
}: SidebarProps<TFormData>) => {
  const hasStepWithError = stepsWithErrors.some(Boolean);
  return (
    <>
      <aside className="sticky top-24 h-fit">
        <div className="w-24">
          {provider && <ProviderLogo provider={provider} />}
        </div>

        <button
          className="mt-5 mb-10 flex items-center border-none bg-none text-gray-lightmode-300 outline-none"
          onClick={handleClickSwitchProvider}
        >
          <SwitchIcon className="text-gray-lightmode-300" />
          {switchProviderText}
        </button>

        <div className="flex flex-col">
          <Text inheritColor className="mb-6" size="large" weight="medium">
            Steps
          </Text>

          <motion.ol
            animate="show"
            className="ml-4"
            data-testid="sidebar-nav"
            initial="hidden"
            variants={listVariants}
          >
            {stepsData.map(
              (step: StepDataWithPath<TFormData>, index: number) => (
                <SidebarNavItem<TFormData>
                  key={`${provider}-${step.title}`}
                  hasError={
                    // normal step
                    stepsWithErrors[index] ||
                    // confirmation step
                    (index + 1 === stepsData.length && hasStepWithError)
                  }
                  step={step}
                  stepNumber={index + 1}
                />
              ),
            )}
          </motion.ol>
        </div>
      </aside>
    </>
  );
};
