import { Text } from '@workos-inc/component-library';
import { FC } from 'react';
import { Link } from '../../../../components/link';
import { StepData } from '../../../../shared/step-navigation/interfaces/step-data';
import { MetadataXmlStep } from '../../metadata-step';
import { ShibbolethGenericSamlFormFields } from './shibboleth-generic-saml-steps';

const Component: FC = () => (
  <MetadataXmlStep
    idpEntityIdPlaceholder="https://mysamlserver.com/abc123..."
    idpSsoUrlPlaceholder="https://mysamlserver.com/..."
  >
    <Text>
      Download the IdP metadata from the Shibboleth instance. Refer to the{' '}
      <Link
        newTab
        appearance="highlight"
        href="https://shibboleth.atlassian.net/wiki/spaces/CONCEPT/pages/928645275/MetadataForIdP"
      >
        Shibboleth documentation
      </Link>{' '}
      for more information on this metadata file. Upload the file below.
    </Text>
  </MetadataXmlStep>
);

export const uploadIdentityProviderMetadata: StepData<ShibbolethGenericSamlFormFields> =
  {
    title: 'Upload Identity Provider Metadata',
    component: Component,
    formKeys: [
      'saml_idp_entity_id',
      'saml_idp_metadata_xml',
      'saml_idp_url',
      'saml_x509_certificates',
    ],
  };
