import { Dialog } from '@workos-inc/component-library';
import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { DirectoryType } from '../../../../graphql/generated';
import { usePortalSession } from '../../../components/portal-session-provider';
import {
  ProviderSelection,
  useProviderSelection,
} from '../../../components/provider-selection';
import { DirectoryProviderLabels } from '../../../utils/constants';
import { isDomain } from '../../../utils/is-domain';
import { dsyncProviderConfigurators } from '../dsync-providers-configurators';
import { useDsyncStore } from '../dsync-store-provider';
import { useEnabledDirectories } from '../hooks/use-enabled-directories';
import { useSwitchDirectory } from '../hooks/use-switch-directory';
import { supportedDirectoryTypes } from '../interfaces/supported-directory-type';

export interface SwitchDirectoryModalProps {
  open: boolean;
  setOpen(open: boolean): void;
}

export const SwitchDirectoryModal: FC<Readonly<SwitchDirectoryModalProps>> = ({
  open,
  setOpen,
}) => {
  const navigate = useNavigate();
  const enabledDirectories = useEnabledDirectories(supportedDirectoryTypes);
  const { domains } = usePortalSession();
  const { directory } = useDsyncStore();
  const [directorySwitchingState, switchDirectory] = useSwitchDirectory();
  const {
    configData,
    handleSelectedProvider,
    activeConfigurator,
    providerSelectionProps,
    resetProviderSelectionState,
  } = useProviderSelection({
    initialDirectoryDomain: domains[0],
    initialProvider: directory?.type,
    requestState: directorySwitchingState,
    providers: enabledDirectories,
    providerConfigurators: dsyncProviderConfigurators,
    providerLabels: DirectoryProviderLabels,
    onSubmit: async (data) => {
      if (!data.selectedProvider) {
        return;
      }

      await switchDirectory(data);

      void navigate('/dsync');
    },
  });

  const title =
    activeConfigurator?.title ||
    'Are you sure you want to change your Directory Provider?';
  const description =
    activeConfigurator?.description ||
    'This action will reset any information associated with your directory.';
  const isValidDomain = isDomain(configData.directoryDomain || '');

  let isSubmissionDisabled = false;
  if (
    (activeConfigurator &&
      (directorySwitchingState.type === 'waiting' || !isValidDomain)) ||
    directory?.type === configData.selectedProvider
  ) {
    isSubmissionDisabled = true;
  }

  return (
    <Dialog
      acceptText={
        activeConfigurator &&
        configData.selectedProvider === DirectoryType.GoogleWorkspace
          ? 'Add Domain'
          : 'Switch Directory Provider'
      }
      className="max-w-2xl"
      description={description}
      isAcceptDisabled={isSubmissionDisabled}
      onAccept={handleSelectedProvider}
      onOpenChange={(isModalOpen) => {
        setOpen(isModalOpen);
        resetProviderSelectionState();
      }}
      open={open}
      title={title}
    >
      <ProviderSelection
        value={configData.selectedProvider}
        {...providerSelectionProps}
      />
    </Dialog>
  );
};
