import { useFeatureToggle } from '@flopflip/react-broadcast';
import { Button, Popover, Text } from '@workos-inc/component-library';
import { FC } from 'react';
import { ArrowLeft } from 'react-feather';
import { ExternalLink } from '../link';
import { usePortalSession } from '../portal-session-provider';

interface TopnavProps {}

export const Topnav: FC<Readonly<TopnavProps>> = () => {
  const hidePoweredByWorkos = useFeatureToggle('hidePoweredByWorkos');

  const { appName, logoUrl, returnUrl, showLoseProgressOnExitWarning } =
    usePortalSession();

  const handleExitClick = (): void => {
    window.location.href = returnUrl;
  };

  const backButton = () => {
    if (showLoseProgressOnExitWarning && returnUrl) {
      return (
        <Popover>
          <Popover.Trigger>
            <Button
              appearance="minimal"
              data-testid="popover-topnav-back-button"
              iconLeft={<ArrowLeft size={16} />}
            >
              Back to {appName}
            </Button>
          </Popover.Trigger>
          <Popover.Content align="start" className="w-80 p-4" sideOffset={-8}>
            <Text as="h3" weight="medium">
              Confirm Exit
            </Text>
            <Text>
              Exiting the set-up will lose your progress. Are you sure you want
              to exit?
            </Text>

            <div className="mt-2 flex items-center justify-between">
              <Popover.Close asChild>
                <Button appearance="minimal" size="small">
                  Cancel
                </Button>
              </Popover.Close>

              <Button
                data-testid="confirm-exit-button"
                onClick={handleExitClick}
                size="small"
              >
                Yes, Exit
              </Button>
            </div>
          </Popover.Content>
        </Popover>
      );
    }

    if (returnUrl) {
      return (
        <Button
          appearance="minimal"
          data-testid="topnav-back-button"
          iconLeft={<ArrowLeft size={16} />}
          onClick={handleExitClick}
        >
          Back to {appName}
        </Button>
      );
    } else {
      return <span />;
    }
  };

  return (
    <header className="sticky top-0 z-10 h-16 border-b border-gray-lightmode-200 bg-white px-8 leading-[4rem]">
      <div className="mx-auto flex h-full max-w-7xl items-center justify-between">
        {backButton()}

        {logoUrl ? (
          <div className="flex w-[125px] items-center">
            <img data-testid="logo" src={logoUrl} />
          </div>
        ) : (
          !hidePoweredByWorkos && (
            <ExternalLink
              newTab
              className="text-gray-lightmode-400"
              href="https://www.workos.com?utm_source=adminportal_nav&utm_medium=setup&utm_campaign=adminportal"
            >
              <Text inheritColor as="p">
                Powered by WorkOS
              </Text>
            </ExternalLink>
          )
        )}
      </div>
    </header>
  );
};
