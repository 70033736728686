import { Slot } from '@radix-ui/react-slot';
import { Skeleton, TableV2 as Table } from '@workos-inc/component-library';
import classNames from 'classnames';
import { ComponentProps, FC, ReactNode } from 'react';
import { ChevronRight, Crosshair } from 'react-feather';
import { AuditLogEventFragment } from '../../../../graphql/generated';
import { Link } from '../../../components/link/internal-link';
import { formatAuditLogEventDate } from '../format-audit-log-event-date';
import { AuditLogsActorIcon } from './audit-logs-actor-icon';

const TABLE_HEADERS = [
  {
    name: 'Actor',
    id: 'actor',
    width: 'w-4/12',
  },
  {
    name: 'Event',
    id: 'event',
    width: 'w-5/12',
  },
  {
    name: 'Date and time',
    id: 'occurredAt',
    width: 'w-3/12',
  },
];

interface AuditLogsTableProps {
  events: AuditLogEventFragment[];
  isLoading: boolean;
}

export const AuditLogsTable: FC<AuditLogsTableProps> = ({
  events,
  isLoading,
}) => (
  <div className="overflow-hidden rounded ring-1 ring-gray-lightmode-200 dark:ring-gray-darkmode-200">
    <Table className="min-w-full table-fixed">
      <Table.RowGroup as="thead">
        {TABLE_HEADERS.map((header) => (
          <Table.ColumnHeader
            key={header.id}
            className={classNames(
              header.width,
              '!px-3 border-b border-gray-lightmode-200 dark:border-gray-darkmode-200',
            )}
          >
            {header.name}
          </Table.ColumnHeader>
        ))}
      </Table.RowGroup>
      <Table.RowGroup as="tbody">
        {events.map((event) => {
          const time =
            formatAuditLogEventDate(event.occurredAt, 'H:mm:ss z') ?? '';
          const date =
            formatAuditLogEventDate(event.occurredAt, 'MMMM dd, yyyy') ?? '';

          return (
            <Table.Row key={event.id} asChild>
              <Link href={`/audit-logs/events/${event.id}`}>
                <TableCell>
                  <CellText isLoading={isLoading}>{event.actor.name}</CellText>
                  <CellText isLoading={isLoading} variant="secondary">
                    <AuditLogsActorIcon
                      actor={event.actor}
                      className="opacity-50"
                      size={14}
                    />
                    {event.actor.type}
                  </CellText>
                </TableCell>
                <TableCell>
                  <CellText isLoading={isLoading}>{event.action}</CellText>
                  <CellText isLoading={isLoading} variant="secondary">
                    <Crosshair className="opacity-50" size={14} />
                    {event.targets.map((target) => target.type).join(', ')}
                  </CellText>
                </TableCell>
                <TableCell>
                  <CellText asChild isLoading={isLoading}>
                    <time dateTime={date}>{date}</time>
                  </CellText>
                  <CellText asChild isLoading={isLoading} variant="secondary">
                    <time dateTime={time}>{time}</time>
                  </CellText>
                  <Chevron />
                </TableCell>
              </Link>
            </Table.Row>
          );
        })}
      </Table.RowGroup>
    </Table>
  </div>
);

interface TableCellProps extends ComponentProps<typeof Table.Cell> {
  isLoading?: boolean;
}

const TableCell: FC<TableCellProps> = ({ children }) => (
  <Table.Cell className="relative !px-3">
    <div className="my-0.5 flex flex-col gap-y-0.5">{children}</div>
  </Table.Cell>
);

interface CellTextProps {
  children: ReactNode;
  asChild?: boolean;
  variant?: 'primary' | 'secondary';
  isLoading?: boolean;
}

const CellText: FC<CellTextProps> = ({
  isLoading,
  children,
  variant = 'primary',
  asChild,
}) => {
  const Comp = asChild ? Slot : 'p';

  return (
    <Skeleton
      className={classNames(
        'py-0',
        variant === 'primary' ? 'h-5 w-7/12' : 'h-[18px] w-4/12',
      )}
      data-testid="audit-logs-table-cell-text-loader"
      show={!isLoading}
    >
      <Comp
        className={classNames(
          variant === 'primary' && 'text-sm text-gray-700 dark:text-gray-200',
          variant === 'secondary' &&
            'inline-flex items-center gap-1 text-[0.75rem] leading-normal text-gray-500 dark:text-gray-400',
        )}
      >
        {children}
      </Comp>
    </Skeleton>
  );
};

const Chevron: FC = () => (
  <div className="absolute inset-y-0 right-0 flex items-center pr-3 text-gray-500">
    <ChevronRight size={16} />
  </div>
);

export const testables = {
  TABLE_HEADERS,
};
