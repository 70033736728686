import { Pill, Table, Text } from '@workos-inc/component-library';
import Link from 'next/link';
import { FC } from 'react';
import { ConnectionSessionFragment } from '../../../../../../graphql/deserializers/connection-session-deserializer';
import { formatDate, formatName } from '../../../../../utils/formatters';
import { getSessionStateBadge } from '../../../utils/get-session-state-badges';

export interface ConnectionRecentEventsTableProps {
  events: ConnectionSessionFragment[];
}

export const ConnectionRecentEventsTable: FC<
  Readonly<ConnectionRecentEventsTableProps>
> = ({ events }) => {
  const formattedEvents = events.map((event) => ({
    id: event.id,
    profile: event.profile,
    state: event.state,
    createdAt: event.createdAt,
    href: `/sso/session/${event.id}`,
    profileEmail: event.profile?.email ?? '-',
  }));

  return (
    <Table
      columns={[
        {
          header: 'Email',
          key: 'profileEmail',
          id: 'profileEmail',
          cell: ({ value }) => (
            <Text as="p" size="small">
              {value}
            </Text>
          ),
        },
        {
          header: 'Name',
          key: 'profile',
          id: 'profile',
          cell: ({ value }) => <>{formatName(value)}</>,
        },
        {
          header: 'State',
          key: 'state',
          id: 'state',
          cell: ({ value }) => (
            <Pill appearance={getSessionStateBadge(value)} size="small">
              {value}
            </Pill>
          ),
        },
        {
          header: 'Timestamp',
          key: 'createdAt',
          id: 'createdAt',
          cell: ({ value }) => <>{formatDate(value)}</>,
        },
      ]}
      data={formattedEvents}
      link={Link}
    />
  );
};
