import { PropsWithChildren, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { ConnectionType, DirectoryType } from '../../../graphql/generated';
import { OverflowPage } from '../../../layouts/page';
import { Article } from '../../components/typography';
import { AnyObject, RecordStateDispatcher } from '../../hooks/use-record-state';
import {
  FormDataErrors,
  useFormContext,
} from '../form-data-provider/form-data-provider';
import { StepDataWithPath } from './interfaces/step-data';
import { Sidebar } from './sidebar';
import { StepTitle } from './step-title';
import { StepsStoreProvider } from './steps-store-provider';

export interface StepNavigationProps<TFormData extends AnyObject> {
  onClickSwitchProvider: () => void;
  onUpdateWithChanges: (
    formData: TFormData,
    setFormErrors: RecordStateDispatcher<FormDataErrors<TFormData>>,
  ) => Promise<void>;
  provider: DirectoryType | ConnectionType;
  stepsData: StepDataWithPath<TFormData>[];
  stepsUrlBase: string;
  switchProviderText?: string;
}

export const StepNavigation = <TFormData extends AnyObject>({
  children,
  onClickSwitchProvider: handleClickSwitchProvider,
  onUpdateWithChanges: handleOnUpdateWithChanges,
  provider,
  stepsData,
  stepsUrlBase,
  switchProviderText = 'Switch Provider',
}: PropsWithChildren<Readonly<StepNavigationProps<TFormData>>>) => {
  const { pathname } = useLocation();
  const { formErrors } = useFormContext<TFormData>();
  const stepsWithErrors = stepsData.map(
    (stepData) => !!stepData.formKeys?.some((key) => !!formErrors[key]),
  );

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, [pathname]);

  return (
    <StepsStoreProvider<TFormData>
      onUpdateWithChanges={handleOnUpdateWithChanges}
      stepsData={stepsData}
      stepsUrlBase={stepsUrlBase}
    >
      <OverflowPage>
        <section className="grid grid-cols-[300px_680px] gap-20">
          <Sidebar
            onClickSwitchProvider={handleClickSwitchProvider}
            provider={provider}
            stepsData={stepsData}
            stepsWithErrors={stepsWithErrors}
            switchProviderText={switchProviderText}
          />

          <Article>
            <StepTitle stepsData={stepsData} />

            {children}
          </Article>
        </section>
      </OverflowPage>
    </StepsStoreProvider>
  );
};
