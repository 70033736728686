import classNames from 'classnames';
import { FC, HTMLProps, useCallback, useEffect, useRef, useState } from 'react';

export type FakeProgressBarState =
  | 'idle'
  | 'loading'
  | 'complete'
  | 'error'
  | '';

interface FakeProgressBarProps
  extends Omit<HTMLProps<HTMLDivElement>, 'children'> {
  state: FakeProgressBarState;
  onComplete?: () => void;
}
export const FakeProgressBar: FC<FakeProgressBarProps> = ({
  state,
  onComplete,
  ...props
}) => {
  const interval = useRef<NodeJS.Timeout>();
  const progressRef = useRef(0);
  const [progress, setProgress] = useState(0);

  const increment = useCallback(() => {
    progressRef.current = Math.min(
      0.9,
      progressRef.current < 0.7
        ? progressRef.current + Math.random() * 0.15 + 0.05
        : progressRef.current + 0.01,
    );
    setProgress(progressRef.current);

    if (progressRef.current < 0.9) {
      interval.current = setTimeout(increment, Math.random() * 1000 + 300);
    }
  }, []);

  useEffect(() => {
    if (interval.current) {
      clearInterval(interval.current);
    }

    if (state === 'idle') {
      setProgress(0);
      progressRef.current = 0;
    } else if (state === 'complete' || state === 'error') {
      setProgress(1);
      progressRef.current = 1;
    } else if (state === 'loading') {
      interval.current = setTimeout(increment, Math.random() * 1000 + 300);
    }

    return () => {
      if (interval.current) {
        clearInterval(interval.current);
      }
    };
  }, [increment, state]);

  return (
    <Container {...props}>
      <Progress
        className={classNames(
          state === 'complete' && '-hue-rotate-90',
          state === 'error' && 'hue-rotate-90',
        )}
        data-testid="fake-progress-bar"
        onTransitionEnd={() => {
          if (state === 'complete') {
            onComplete?.();
          }
        }}
        style={{ transform: `translateX(${-100 + 100 * progress}%)` }}
      >
        <Stripes />
      </Progress>
    </Container>
  );
};

const Container: FC<HTMLProps<HTMLDivElement>> = (props) => (
  <div
    {...props}
    className={classNames(
      'relative w-full w-min-48 h-[6px] bg-gray-50 overflow-hidden shadow-[inset_0px_1px_3px_rgba(0,0,0,0.25),inset_0_0_0_1px_#D3D2D5] rounded',
      props.className,
    )}
    /**
     * Fix safari bug with overflow hidden and border-radius
     * https://gist.github.com/ayamflow/b602ab436ac9f05660d9c15190f4fd7b
     */
    style={{
      WebkitMaskImage: '-webkit-radial-gradient(white, black)',
      ...(props.style ?? {}),
    }}
  />
);

const Progress: FC<HTMLProps<HTMLDivElement>> = (props) => (
  <div
    {...props}
    className={classNames(
      // eslint-disable-next-line max-len
      'relative w-full h-full overflow-hidden bg-gradient-to-b rounded from-[#6565EC] to-[#5055D7] transition-all duration-500 ease-[cubic-bezier(0.5,1,0.89,1)]',
      props.className,
    )}
  />
);

const Stripes: FC<HTMLProps<HTMLDivElement>> = (props) => (
  <div
    {...props}
    className={classNames(
      'absolute top inset-y-0 -inset-x-5 bg-[length:10px_10px] animate-fake-progress-bar-stripes',
      props.className,
    )}
    style={{
      backgroundImage:
        // eslint-disable-next-line max-len
        'linear-gradient(-45deg, rgba(255, 255, 255, 0.1) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.1) 50%, rgba(255, 255, 255, 0.1) 75%, transparent 75%, transparent)',
      ...(props.style && {}),
    }}
  />
);
