import { useState } from 'react';
import { ConnectionType } from '../../../../graphql/generated';
import { usePortalSession } from '../../../components/portal-session-provider';
import { ProviderSelectionState } from '../../../components/provider-selection';
import { useFormContext } from '../../../shared/form-data-provider';
import { EventName } from '../../../utils/constants';
import { graphql } from '../../../utils/graphql';
import { trackEvent } from '../../../utils/track-event';
import { RequestState } from '../interfaces/request-state';
import { useSsoStore } from '../sso-store-provider';

/**
 * A hook that is used to switch the current connection type to something else
 */
export const useSwitchConnection = () => {
  const [switchingState, setSwitchingState] = useState<RequestState>({
    type: 'idle',
  });
  const { setFormErrors } = useFormContext();
  const { connection, setSsoStore } = useSsoStore();
  const { organization } = usePortalSession();

  const switchConnection = async (
    providerSwitchingData: ProviderSelectionState<ConnectionType>,
  ): Promise<void> => {
    const { selectedProvider } = providerSwitchingData;

    if (!selectedProvider || !organization) {
      return;
    }

    setSwitchingState({ type: 'waiting' });
    setFormErrors({});

    try {
      await graphql().DeleteConnection({ id: connection.id });

      const response = await graphql().CreateConnection({
        name: organization.name,
        type: selectedProvider,
      });

      const data = response?.data?.portal_createConnection;

      if (data) {
        trackEvent(EventName.IdentityProviderSwitched, {
          from_connection_type: connection.type,
          to_connection_type: selectedProvider,
        });

        setSsoStore({ connection: data });
      }
    } catch (error) {
      setSwitchingState({
        type: 'failed',
      });
    }
  };

  return [switchingState, switchConnection] as const;
};
