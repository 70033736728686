import { Text } from '@workos-inc/component-library';
import { FC } from 'react';
import { Img } from '../../../../components/image';
import { ExternalLink } from '../../../../components/link';
import { StepData } from '../../../../shared/step-navigation/interfaces/step-data';
import { MapAttributesStep } from '../../map-attributes-step/map-attributes-step';

const Component: FC = () => (
  <MapAttributesStep>
    <Text>
      There are additional attributes needed by the application for it to fully
      function.
    </Text>

    <Text>
      BambooHR by default will provide the following additional attributes that
      are available for mapping: "id", "status", "lastName", "firstName",
      "homeEmail", "workEmail", "department", and "displayName". However,
      additional attributes can be added to the "raw_attributes" by request.
    </Text>

    <Img
      priority
      height={1200}
      src="/admin-portal/directory-sync/bamboo-hr/v1/bamboo-hr-5.png"
      width={1450}
    />

    <Text>
      The full list of available attributes from BambooHR can be found{' '}
      <ExternalLink
        newTab
        appearance="highlight"
        href={'https://documentation.bamboohr.com/docs/list-of-field-names'}
      >
        here
      </ExternalLink>
      . If you need attributes other than the ones found on the default list,
      reach out to us at{' '}
      <ExternalLink
        newTab
        appearance="highlight"
        href="mailto: support@workos.com"
      >
        support@workos.com
      </ExternalLink>{' '}
      and we can add them on your behalf.
    </Text>

    <Text>
      For example, if you wanted to use the value of "workEmail", you would
      enter "workEmail" in the Directory Provider Value field.
    </Text>
  </MapAttributesStep>
);

export const setUpAttributeMapping: StepData = {
  title: 'Set up Attribute Mapping',
  customAttributesStep: true,
  component: Component,
};
