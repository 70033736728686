import { unreachable } from '@workos-inc/standard';
import { useCallback, useState } from 'react';
import * as React from 'react';
import {
  UpdateConnectionFromMetadataUrlMutation,
  UpdateConnectionFromMetadataXmlMutation,
  UpdateConnectionMutation,
} from '../../../../../graphql/generated';
import { useFeature } from '../../../../../utils/feature-flags';
import { StateAction } from '../../../../hooks/use-record-state';
import { useFormContext } from '../../../../shared/form-data-provider/form-data-provider';
import { logError } from '../../../../utils/logger';
import { RequestState } from '../../interfaces/request-state';
import { SsoFormErrors } from '../../interfaces/sso-form-errors';
import { SsoFormMetadata } from '../../interfaces/sso-form-metadata';
import { SsoStore } from '../../sso-store-provider/sso-store-provider';
import {
  completeConnectionFromMetadataUrl,
  completeConnectionFromMetadataXml,
  completeConnectionManually,
} from './utils';
import { getUpdateType } from './utils/get-update-type';
import { parseInvalidSamlX509CertificateError } from './utils/parse-invalid-saml-x509-certificate-error';

export const useUpdateConnection = ({
  ssoStore: { selectedConfiguration, connection },
  setSsoStore,
}: UseUpdateConnectionArg) => {
  const isOktaOinEnabled = useFeature('oktaOinAdminPortalSso');
  const isOneLoginOacEnabled = useFeature('oneLoginOacAdminPortalSso');
  const { setFormErrors } = useFormContext();
  const [updateState, setUpdateState] = useState<RequestState>({
    type: 'idle',
  });

  const updateConnection = useCallback(
    async (formData: SsoFormMetadata) => {
      setFormErrors({});

      let response: UpdateConnectionResponseType | undefined;

      const updateType = getUpdateType({
        connection,
        isOktaOinEnabled,
        isOneLoginOacEnabled,
        selectedConfiguration,
      });

      if (!updateType) {
        return;
      }

      try {
        setUpdateState({ type: 'waiting' });
        switch (updateType) {
          case 'Manual':
            response = await completeConnectionManually(formData, connection);
            break;
          case 'MetadataUrl':
            response = await completeConnectionFromMetadataUrl(
              formData,
              connection,
            );
            break;
          case 'MetadataXml':
            response = await completeConnectionFromMetadataXml(
              formData,
              connection,
            );
            break;
          case undefined:
            break;
          default:
            return unreachable(updateType);
        }
      } catch (error) {
        setUpdateState({ type: 'failed', value: { error } });
        logError(error);
        return;
      }
      let formErrors: SsoFormErrors | undefined;
      switch (response?.__typename) {
        case 'Portal_ConnectionUpdated':
        case 'Portal_ConnectionUpdatedFromMetadataXml':
        case 'Portal_ConnectionUpdatedFromMetadataUrl':
          setUpdateState({ type: 'success' });
          setSsoStore({ connection: response.connection });
          break;

        case 'ConnectionNotFound':
          setUpdateState({
            type: 'failed',
            value: { error: response, errorCode: response.__typename },
          });
          break;
        case 'InvalidSamlX509Certificate':
          const { validationError } = response;
          const { field, message } = parseInvalidSamlX509CertificateError(
            validationError,
            updateType,
          );
          formErrors = {
            [field]: {
              message,
              value: formData.saml_x509_certificates?.[0] || '',
            },
          };
          break;
        case 'Portal_MetadataFetchFailed':
          formErrors = {
            saml_idp_metadata_url: {
              message: response.reason,
              value: formData.saml_idp_metadata_url ?? '',
            },
          };
          break;
        case 'Portal_MetadataParseFailed':
          formErrors = {
            saml_idp_metadata_xml: {
              message: response.reason,
              value: formData.saml_idp_metadata_xml || '',
            },
          };
          break;

        case 'VerifyConnectionFailed':
          formErrors = response.connectionErrors.reduce((acc, curr) => {
            const formFieldKey = curr.field as keyof SsoFormMetadata;
            const formFieldVal =
              formFieldKey === 'saml_x509_certificates'
                ? formData[formFieldKey]?.[0]
                : formData[formFieldKey];

            acc[formFieldKey] = {
              message: curr.error,
              value: formFieldVal || '',
            };
            return acc;
          }, {} as SsoFormErrors);
          break;
        case undefined:
          break;
        default:
          unreachable(response);
      }
      if (formErrors) {
        setFormErrors(formErrors);
      }
    },
    [
      setSsoStore,
      isOktaOinEnabled,
      isOneLoginOacEnabled,
      connection,
      selectedConfiguration,
      setFormErrors,
    ],
  );
  return [updateState, updateConnection] as const;
};

type UpdateConnectionResponseType =
  | UpdateConnectionMutation['portal_updateConnection']
  | UpdateConnectionFromMetadataUrlMutation['portal_updateConnectionFromMetadataUrl']
  | UpdateConnectionFromMetadataXmlMutation['portal_updateConnectionFromMetadataXml'];

interface UseUpdateConnectionArg {
  ssoStore: SsoStore;
  setSsoStore: React.Dispatch<StateAction<SsoStore>>;
}
