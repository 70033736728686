import {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { AnyObject, RecordStateDispatcher } from '../../hooks/use-record-state';
import {
  FormDataErrors,
  useFormContext,
} from '../form-data-provider/form-data-provider';
import { StepDataWithPath } from './interfaces/step-data';
import { extractCurrentStepFromPathname } from './utils';

type StepsStoreContext<TFormData extends AnyObject> = {
  currentStepNumber: number;
  currentStepData?: StepDataWithPath<TFormData>;
  stepByFormKey: Record<keyof TFormData, number>;
  handleInputChange: (event: {
    target: { name: string; value: string };
  }) => void;
  handleNextStep: () => Promise<void>;
  handleConfirmationStep: () => Promise<void>;
  isUpdating: boolean;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const StepsContext = createContext<StepsStoreContext<any> | null>(null);

export const useStepsStore = <TFormData extends AnyObject>() => {
  const context = useContext<StepsStoreContext<TFormData> | null>(StepsContext);

  if (!context) {
    throw new Error('useStepsStore can only be used within StepsContext');
  }

  return context;
};

export interface StepsStoreProviderProps<TFormData extends AnyObject> {
  onUpdateWithChanges: (
    formData: TFormData,
    setFormError: RecordStateDispatcher<FormDataErrors<TFormData>>,
  ) => Promise<void>;
  stepsData: StepDataWithPath<TFormData>[];
  stepsUrlBase: string;
}

export const StepsStoreProvider = <TFormData extends AnyObject>({
  children,
  onUpdateWithChanges,
  stepsData,
  stepsUrlBase,
}: PropsWithChildren<StepsStoreProviderProps<TFormData>>) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const stepByFormKey = useMemo(
    () =>
      stepsData.reduce((_stepByFormKey, curr, i) => {
        curr.formKeys?.forEach((formKey) => {
          _stepByFormKey[formKey] = i + 1;
        });
        return _stepByFormKey;
      }, {} as Record<keyof TFormData, number>),
    [stepsData],
  );

  const currentStepNumber = useMemo(
    () => extractCurrentStepFromPathname(stepsUrlBase, pathname),
    [pathname, stepsUrlBase],
  );
  const currentStepData = stepsData[currentStepNumber];
  const { formData, setFormData, setFormErrors } = useFormContext<TFormData>();
  const [isUpdating, setIsUpdating] = useState<boolean>(false);

  const handleInputChange = useCallback(
    (event: { target: { name: string; value: string } }) => {
      const payload = { [event.target.name]: event.target.value };

      setFormData({ ...formData, ...payload });
    },
    [formData, setFormData],
  );

  const handleConfirmationStep = useCallback(async () => {
    const nextStepNumber = currentStepNumber + 1;
    const nextStep = stepsData[nextStepNumber - 1];
    if (!nextStep) {
      setIsUpdating(true);
      await onUpdateWithChanges(formData, setFormErrors);
      setIsUpdating(false);
    }
  }, [
    stepsData,
    formData,
    setFormErrors,
    currentStepNumber,
    onUpdateWithChanges,
  ]);

  const handleNextStep = useCallback(async () => {
    setIsUpdating(true);

    const nextStepNumber = currentStepNumber + 1;
    const nextStep = stepsData[nextStepNumber - 1];
    const nextStepPath = `${stepsUrlBase}/${nextStepNumber}`;

    if (nextStep?.confirmationStep && Object.keys(formData).length > 0) {
      await onUpdateWithChanges(formData, setFormErrors);
    }

    setIsUpdating(false);
    navigate(nextStepPath);
  }, [
    currentStepNumber,
    formData,
    navigate,
    onUpdateWithChanges,
    setFormErrors,
    stepsData,
    stepsUrlBase,
  ]);

  const context = useMemo(
    () => ({
      currentStepData,
      currentStepNumber,
      stepByFormKey,
      handleConfirmationStep,
      handleInputChange,
      handleNextStep,
      isUpdating,
    }),
    [
      currentStepData,
      currentStepNumber,
      stepByFormKey,
      handleConfirmationStep,
      handleInputChange,
      handleNextStep,
      isUpdating,
    ],
  );

  return (
    <StepsContext.Provider value={context}>{children}</StepsContext.Provider>
  );
};
