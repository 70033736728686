import { Text } from '@workos-inc/component-library';
import { FC } from 'react';
import { Img } from '../../../../components/image';
import { StepData } from '../../../../shared/step-navigation/interfaces/step-data';
import { useStepsStore } from '../../../../shared/step-navigation/steps-store-provider';
import { useSsoStore } from '../../sso-store-provider';
import { AdpCertUploadForm } from './adp-cert-upload-form';

const Component: FC = () => {
  const { connection } = useSsoStore();
  const { handleNextStep } = useStepsStore();

  return (
    <>
      <Text>
        ADP uses a two fold certificate method with an SSL certificate and an
        SSL Private Key. The SSL Private Key is only displayed one time when the
        certificate is generated. If the certificate has already been generated,
        the IT Administrator who generated it should have also received the
        Private Key otherwise a new certificate and key can be generated by
        browsing to the certificates tab on the left hand navigation.
      </Text>

      <Text>
        You can download the SSL Certificate from ADP by browsing to
        'Certificate' on the left hand nav bar. You can also create a new SSL
        Certificate and Private Key pair if necessary.
      </Text>

      <Img
        priority
        height={642}
        src="/admin-portal/sso/adp-oidc/v1/adp-oidc-7.png"
        width={1216}
      />

      <AdpCertUploadForm
        connectionId={connection.id}
        onNextStep={handleNextStep}
      />
    </>
  );
};

export const uploadTheSslCertificateAndPrivateKeyFromAdp: StepData = {
  title: 'Upload the SSL Certificate and Private Key from ADP',
  component: Component,
};
