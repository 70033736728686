import { Dialog } from '@workos-inc/component-library';
import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  ProviderSelection,
  useProviderSelection,
} from '../../../components/provider-selection';
import { SsoProviderLabels } from '../../../utils/constants';
import { useEnabledConnections } from '../hooks/use-enabled-connections';
import { useSwitchConnection } from '../hooks/use-switch-connection';
import { supportedConnectionTypes } from '../interfaces/supported-connection-type';
import { useSsoStore } from '../sso-store-provider';

export interface SwitchConnectionModalProps {
  open: boolean;
  setOpen: (open: boolean) => void;
}

export const SwitchConnectionModal: FC<
  Readonly<SwitchConnectionModalProps>
> = ({ open, setOpen }) => {
  const navigate = useNavigate();
  const enabledConnections = useEnabledConnections(supportedConnectionTypes);
  const { connection } = useSsoStore();
  const [connectionSwitchingState, switchConnection] = useSwitchConnection();

  const {
    activeConfigurator,
    configData,
    handleSelectedProvider,
    providerSelectionProps,
    resetProviderSelectionState,
  } = useProviderSelection({
    initialProvider: connection?.type,
    onSubmit: async (data) => {
      if (!data.selectedProvider) {
        return;
      }

      await switchConnection(data);

      void navigate('/sso');
    },
    providerLabels: SsoProviderLabels,
    providers: enabledConnections,
    requestState: connectionSwitchingState,
  });

  const title =
    activeConfigurator?.title ||
    'Are you sure you want to change your Identity Provider?';

  const description =
    activeConfigurator?.description ||
    'This action will reset any information associated with your connection.';

  let isSubmissionDisabled = false;
  if (
    (activeConfigurator && connectionSwitchingState.type === 'waiting') ||
    connection?.type === configData.selectedProvider
  ) {
    isSubmissionDisabled = true;
  }

  return (
    <Dialog
      acceptText="Switch Identity Provider"
      className="max-w-2xl"
      description={description}
      isAcceptDisabled={isSubmissionDisabled}
      onAccept={handleSelectedProvider}
      onOpenChange={(isModalOpen) => {
        setOpen(isModalOpen);
        resetProviderSelectionState();
      }}
      open={open}
      title={title}
    >
      <ProviderSelection
        value={configData.selectedProvider}
        {...providerSelectionProps}
      />
    </Dialog>
  );
};
