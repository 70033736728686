import { Text } from '@workos-inc/component-library';
import { FC } from 'react';
import { Img } from '../../../../components/image';
import { StepData } from '../../../../shared/step-navigation/interfaces/step-data';
import { MapAttributesStep } from '../../map-attributes-step/map-attributes-step';

const Component: FC = () => (
  <MapAttributesStep>
    <Text>
      In addition to configuring attribute mappings in Azure, you also need to
      map these additional attributes the application is expecting.
    </Text>

    <Text>
      To see the names of attributes that you can map from your Directory
      Provider, return to the "Provisioning" tab in your Azure SCIM application
      and navigate to the Provisioning page.
    </Text>

    <Img
      priority
      height={1200}
      src="/admin-portal/directory-sync/azure/v1/azure-15.png"
      width={2820}
    />

    <Text>
      To view the list of available attributes to map for Users, click on the
      "Provision Azure Active Directory Users" button. This will bring you to
      the page that lists all available user attributes Azure provides.
    </Text>

    <Text>
      For example, if you wanted to use the value of "jobTitle", you would enter
      "title" in the Directory Provider Value field.
    </Text>

    <Img
      priority
      height={1376}
      src="/admin-portal/directory-sync/azure/v1/azure-16.png"
      width={1720}
    />
  </MapAttributesStep>
);

export const setUpApplicationAttributeMapping: StepData = {
  title: 'Set up application attribute mapping',
  customAttributesStep: true,
  component: Component,
};
