import * as ComponentLibrary from '@workos-inc/component-library';
import { ReactElement } from 'react';
import { ConnectionType, DirectoryType } from '../../../graphql/generated';

export interface ProviderLogoProps {
  provider: ConnectionType | DirectoryType;
  width?: string;
  className?: string;
  height?: string;
}

export const ProviderLogo = ({
  provider,
  ...props
}: ProviderLogoProps): ReactElement | null => {
  switch (provider) {
    case ConnectionType.Adfssaml:
      return <ComponentLibrary.ProviderAdfs {...props} />;
    case ConnectionType.AdpOidc:
      return <ComponentLibrary.ProviderAdp {...props} />;
    case ConnectionType.Auth0Saml:
      return <ComponentLibrary.ProviderAuth0 {...props} />;
    case ConnectionType.AzureSaml:
      return <ComponentLibrary.ProviderAzure {...props} />;
    case ConnectionType.CasSaml:
      return <ComponentLibrary.ProviderCas {...props} />;
    case ConnectionType.CloudflareSaml:
      return <ComponentLibrary.ProviderCloudflare {...props} />;
    case ConnectionType.ClassLinkSaml:
      return <ComponentLibrary.ProviderClassLink {...props} />;
    case ConnectionType.CyberArkSaml:
    case DirectoryType.CyberArkScimV2_0:
      return <ComponentLibrary.ProviderCyberArk {...props} />;
    case ConnectionType.DuoSaml:
      return <ComponentLibrary.ProviderDuo {...props} />;
    case ConnectionType.GoogleOAuth:
      return <ComponentLibrary.ProviderGoogle {...props} />;
    case ConnectionType.GoogleSaml:
      return <ComponentLibrary.ProviderGoogleSaml {...props} />;
    case ConnectionType.JumpCloudSaml:
      return <ComponentLibrary.ProviderJumpCloud {...props} />;
    case ConnectionType.KeycloakSaml:
      return <ComponentLibrary.ProviderKeycloak {...props} />;
    case ConnectionType.NetIqSaml:
      return <ComponentLibrary.ProviderNetIq {...props} />;
    case ConnectionType.OneLoginSaml:
      return <ComponentLibrary.ProviderOnelogin {...props} />;
    case ConnectionType.OracleSaml:
      return <ComponentLibrary.ProviderOracle {...props} />;
    case ConnectionType.GenericOidc:
      return <ComponentLibrary.ProviderOpenId {...props} />;
    case ConnectionType.GenericSaml:
      return <ComponentLibrary.ProviderSaml {...props} />;
    case ConnectionType.LastPassSaml:
      return <ComponentLibrary.ProviderLastPass {...props} />;
    case ConnectionType.MiniOrangeSaml:
      return <ComponentLibrary.ProviderMiniOrange {...props} />;
    case ConnectionType.PingFederateSaml:
      return <ComponentLibrary.ProviderPingFederate {...props} />;
    case DirectoryType.PingFederateScimV2_0:
      return <ComponentLibrary.ProviderPingFederate {...props} />;
    case ConnectionType.PingOneSaml:
      return <ComponentLibrary.ProviderPingOne {...props} />;
    case ConnectionType.RipplingSaml:
      return <ComponentLibrary.ProviderRippling {...props} />;
    case ConnectionType.SalesforceSaml:
      return <ComponentLibrary.ProviderSalesforce {...props} />;
    case ConnectionType.SimpleSamlPhpSaml:
      return <ComponentLibrary.ProviderSimpleSaml {...props} />;
    case ConnectionType.VMwareSaml:
      return <ComponentLibrary.ProviderVMWare {...props} />;
    case DirectoryType.AzureScimv2_0:
      return <ComponentLibrary.ProviderAzureAd {...props} />;
    case DirectoryType.BambooHr:
      return <ComponentLibrary.ProviderBambooHR {...props} />;
    case DirectoryType.BreatheHr:
      return <ComponentLibrary.ProviderBreatheHr {...props} />;
    case DirectoryType.CezanneHr:
      return <ComponentLibrary.ProviderCezanneHr {...props} />;
    case DirectoryType.FourthHr:
      return <ComponentLibrary.ProviderFourthHr {...props} />;
    case DirectoryType.GoogleWorkspace:
      return <ComponentLibrary.ProviderGoogle {...props} />;
    case DirectoryType.Gusto:
      return <ComponentLibrary.ProviderGusto {...props} />;
    case DirectoryType.Hibob:
      return <ComponentLibrary.ProviderHibob {...props} />;
    case DirectoryType.JumpCloudScimV2_0:
      return <ComponentLibrary.ProviderJumpCloud {...props} />;
    case ConnectionType.OktaSaml:
    case DirectoryType.OktaScimv1_1:
    case DirectoryType.OktaScimv2_0:
      return (
        <ComponentLibrary.ProviderOkta
          {...props}
          data-testid="okta-provider-logo"
        />
      );
    case DirectoryType.OneLoginScimV2_0:
      return <ComponentLibrary.ProviderOnelogin {...props} />;
    case DirectoryType.PeopleHr:
      return <ComponentLibrary.ProviderPeopleHr {...props} />;
    case DirectoryType.Rippling:
      return <ComponentLibrary.ProviderRippling {...props} />;
    case DirectoryType.GenericScimv1_1:
      return <ComponentLibrary.ProviderScim {...props} />;
    case DirectoryType.GenericScimv2_0:
      return <ComponentLibrary.ProviderScim {...props} />;
    case DirectoryType.Workday:
      return <ComponentLibrary.ProviderWorkday {...props} />;
    default:
      return <ComponentLibrary.ProviderOther className={props.className} />;
  }
};
