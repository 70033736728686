import { Text } from '@workos-inc/component-library';
import { FC } from 'react';
import { Img } from '../../../../components/image';
import { StepData } from '../../../../shared/step-navigation/interfaces/step-data';
import { StepContent } from '../../../../shared/step-navigation/step-content';
import { ConnectionAttributeMapping } from '../../attribute-mapping';
import { useSsoStore } from '../../sso-store-provider';

const Component: FC = () => {
  const { connection } = useSsoStore();

  return (
    <StepContent confirmLabel="I've finished configuring the Attribute Statements and clicked “Next”.">
      <Text>Continue scrolling to the Attribute Statements configuration.</Text>

      <Img
        priority
        className="my-8"
        height={603}
        src="/images/e8bd92f9-b21d-409a-883f-b46c032f7458.png"
        width={1457}
      />

      <Text>Fill in the following Attribute Statements and click "Next":</Text>

      <ConnectionAttributeMapping connectionType={connection.type} />
    </StepContent>
  );
};

export const configureAttributeStatements: StepData = {
  title: 'Configure Attribute Statements',
  component: Component,
};
